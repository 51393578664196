import { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { contractSocketActionType } from '../../../actions/constants';
import { contractSocket } from '../../../lib/sockets/sockets';

const UPDATED_SIGNATURE_ACTION = 'ELECTRONIC_SIGNATURE_UPDATED';

export const useContractSocket = ({ refresh }) => {
    const { contractId } = useParams();

    const socketActionHandler = useCallback((action) => {
        if (action.type === UPDATED_SIGNATURE_ACTION) {
            refresh();
        }
    }, []);

    const socketConnect = useCallback(() => {
        contractSocket.connect();
        contractSocket.io.on('reconnect', socketConnect);
        contractSocket.emit('action', { type: `${contractSocketActionType}/JOIN`, contractId });

        contractSocket.on('action', socketActionHandler);
    }, []);

    useEffect(() => {
        socketConnect();

        return () => {
            contractSocket.emit('action', {
                type: `${contractSocketActionType}/LEAVE`,
                contractId,
            });
            contractSocket.off('action', socketActionHandler);
            contractSocket.io.off('reconnect', socketConnect);
            contractSocket.disconnect();
        };
    }, []);
};
