import { io, Manager } from 'socket.io-client';

let checklistSocketInstance;
let globalSocketInstance;
let projectSocketInstance;
let requisitionSocketInstance;
let reverseAuctionSocketInstance;
let userSocketInstance;
let vendorProposalSocketInstance;
let contractSocketInstance;

// Do not connect to socket.io in supportboard (there is no sync server to connect to) or on the server
if (process.env.APP_NAME !== 'support' && !process.env.SERVER) {
    // Sockets that should auto-connect
    globalSocketInstance = io(process.env.SOCKET_HOST);
    userSocketInstance = io(`${process.env.SOCKET_HOST}/users`);

    // Sockets that should connect given a specific context. In this case, they are handled in their given parent components.
    checklistSocketInstance = io(`${process.env.SOCKET_HOST}/checklists`, { autoConnect: false });
    projectSocketInstance = io(`${process.env.SOCKET_HOST}/projects`, { autoConnect: false });
    requisitionSocketInstance = io(`${process.env.SOCKET_HOST}/requisitions`, {
        autoConnect: false,
    });
    reverseAuctionSocketInstance = io(`${process.env.SOCKET_HOST}/reverseAuctions`, {
        autoConnect: false,
    });
    vendorProposalSocketInstance = io(`${process.env.SOCKET_HOST}/vendorProposals`, {
        autoConnect: false,
    });
    const manager = new Manager(process.env.SOCKET_HOST, {
        autoConnect: false,
        reconnection: false,
    });
    contractSocketInstance = manager.socket('/contracts');
}

export const checklistSocket = checklistSocketInstance;
export const contractSocket = contractSocketInstance;
export const globalSocket = globalSocketInstance;
export const projectSocket = projectSocketInstance;
export const requisitionSocket = requisitionSocketInstance;
export const reverseAuctionSocket = reverseAuctionSocketInstance;
export const userSocket = userSocketInstance;
export const vendorProposalSocket = vendorProposalSocketInstance;
